import { useState } from "react";
import Modal from "react-modal";
import {createTeam, createUser} from "../../services/apis";
import {VisiblePopupError} from "../../services/errors";

function capitalizeAndRemoveLastLetter(word) {
    const withoutLastLetter = word.slice(0, -1);
    return withoutLastLetter;
}

const PopupForm = ({ type = "users", close, k1Initial="", k2Initial="", title="" }) => {
    const [key1, setKey1] = useState(k1Initial);
    const [key2, setKey2] = useState(k2Initial);
    const [errorMessage, setErrorMessage] = useState("");

    if (title === "") {
        title = `Create a new ${capitalizeAndRemoveLastLetter(type)}`;
    }

    const handleKey1Change = (event) => {
        setKey1(event.target.value);
    };
    const handleKey2Change = (event) => {
        setKey2(event.target.value);
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        try {
            const response = await (type === "teams" ? createTeam(key1, key2) : createUser(key1, key2));
            console.log(response.data);
            setErrorMessage("");
            close();
        } catch (error) {
            if (error instanceof VisiblePopupError) {
                setErrorMessage(error.message);
                console.log("where we want to be ");
                console.log(errorMessage);
                setKey1("");
                setKey2("");
            }
        }

    };

    function getFields(type) {
        if (type === "users") {
            return { f1: "Team Name", f2: "Email"}
        } else if (type === "teams") {
            return { f1: "Team Name", f2: "Team Key"}
        }
    }

    function getPlaceholders(type) {
        if (type === "users") {
            return { k1: "Team Name", f2: "Email"};
        } else {
            return { k1: "Team Name", k2: "Team Key" };
        }
    }

    const { k1, k2 } = getPlaceholders(type);
    const { f1, f2 } = getFields(type);

    return (
        <form onSubmit={handleSubmit} className="bg-white bg-opacity-95 p-6 rounded-lg">
            <h2 className="text-lg font-bold mb-4">{title}</h2>
            <div className="mb-4">
                <label htmlFor="field1" className="block text-gray-700 font-bold mb-2">
                    {f1}
                </label>
                <input
                    type="text"
                    className={`appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white ${errorMessage ? "border-red-500 text-red-500" : ""}`}
                    id={f1}
                    value={key1}
                    onChange={handleKey1Change}
                    placeholder={k1}
                />
            </div>
            <div className="mb-6">
                <label htmlFor="field2" className="block text-gray-700 font-bold mb-2">
                    {f2}
                </label>
                <input
                    type="text"
                    className={`appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white ${errorMessage ? "border-red-500 text-red-500" : ""}`}
                    id={f2}
                    value={key2}
                    onChange={handleKey2Change}
                    placeholder={k2}
                />
            </div>
            {errorMessage && (
                <div className="text-red-500">{errorMessage}</div>
            )}
            <button
                type="submit"
                className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
            >
                Submit
            </button>
        </form>
    );
};

export default PopupForm;
