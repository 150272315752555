import React from 'react';
import {useModal} from "react-hooks-use-modal";
import PopupForm from "../forms/PopupForm";


function generateLink(key) {
    return `${process.env.REACT_APP_JIRA_SERVER}/jira/software/c/projects/${key}/issues`;
}

const TeamCard = ({ description }) => {
    const { name, key, id } = description;

    const [Modal, open, close, isOpen] = useModal('root', {
        preventScroll: true,
        closeOnOverlayClick: false
    });

    return (
        <div className="max-w-md mx-auto bg-white rounded-xl overflow-hidden md:max-w-2xl my-4">
            <div className="p-8">
                <div className="uppercase tracking-wide text-sm text-indigo-500 font-semibold">{name}</div>
                <div className="mt-2 text-gray-500">
                    <a href={generateLink(key)} target="_blank" rel="noopener noreferrer" className="inline-block bg-blue-500 hover:bg-blue-700 text-white font-bold py-0.5 px-2 rounded">
                        View all of project's tasks
                    </a>
                </div>
                <div className="mt-2 text-gray-500">Project Key: {key}</div>
                {/*<div className="mt-4 flex">*/}
                {/*    <button onClick={open} className="bg-green-500 hover:bg-green-700 text-white font-semibold text-sm py-1 px-2 rounded mr-2">*/}
                {/*        Add to team*/}
                {/*    </button>*/}
                {/*    <Modal isOpen={isOpen} onRequestClose={close}>*/}
                {/*        <PopupForm type="users" close={close} k1Initial={name} title={"Add a team member"}/>*/}
                {/*    </Modal>*/}
                {/*</div>*/}
            </div>
        </div>
    );
};

export default TeamCard;
