import Cookies from 'js-cookie';
import axios from 'axios';
import {BadAuthenticationError, VisiblePopupError} from "./errors";


function getUrl() {
    console.log("Sending request.");
    const stage = window.location.href.includes("dev.") && !window.location.href.includes("localhost") ? "dev" : "prod";
    return JSON.parse(process.env.REACT_APP_BACKEND_URL)[stage];
}

function getHeaders() {
        return {
            'Content-Type': 'application/json',
            "Authorization": Cookies.get("SessionJWT")
        }
}

function checkBadRequest(response) {
    console.log(response);
    if ('reason' in response?.data && response.data['reason'] === 'Unauthenticated.') {
        Cookies.remove("SessionJWT");
        throw new BadAuthenticationError("Bad creds.")
    } else if ("reason" in response?.data && response.data['status'] != 200) {
        throw new VisiblePopupError(response.data['reason']);
    }
}

export async function getTeamsAndUsers() {
    const users = await getUsers();
    const teams = await getTeams();
    return {u: users, t: teams};
}

export async function getTeams() {
    const url = `${getUrl()}/teams`;
    let response = await axios.get(url, {headers: getHeaders()});
    checkBadRequest(response);
    let usefulResponse = [];
    for (const [key, value] of Object.entries(response.data['result'])) {
        const temp_response = {
            "name": key,
            "key": value['key'],
            "id": value['id']
        };
        usefulResponse.push(temp_response);
    }
    return usefulResponse;
}


export async function getUsers() {
    const url = `${getUrl()}/users`;
    let response = await axios.get(url, {headers: getHeaders()});
    checkBadRequest(response);
    const new_response = response.data['result'];
    console.log(new_response)
    let usefulResponse = []
    for (const [key, value] of Object.entries(new_response)) {
        const temp_response = {
            "name": value['name'],
            'email': value['email'],
            'teams': value['projects'],
            'accountId': value['id']
        }
        usefulResponse.push(temp_response);
    }
    return usefulResponse;
}

export async function getTasks() {
    const url = `${getUrl()}/tasks`;
    let response = await axios.get(url, {headers: getHeaders()});
    checkBadRequest(response);
    const new_response = response.data['result'];
    console.log(new_response)
    return new_response;
}


export async function createTeam(name, key) {
    const url = `${getUrl()}/team`;
    const payload = {
        name: name,
        key: key
    };

    console.log(payload);
    let response = await axios.post(url, payload, { headers: getHeaders() });
    checkBadRequest(response);
    return response.data['result'];
}

export async function createUser(team, email) {
    console.log(team);
    console.log(email);
    const url = `${getUrl()}/user`;
    const payload = {
        email: email,
        project: team
    };


    let response = await axios.post(url, payload, { headers: getHeaders() });
    console.log(response);
    checkBadRequest(response);
    return response.data['result'];
}

export async function deleteTeam(name) {
    const url = `${getUrl()}/team`;
    const payload = {
        name: name
    }
    let response;
    try {
        response = await axios.delete(url, {
            headers: getHeaders(),
            params: payload
        });
        console.log(response.status);
        console.log(response.data);
    } catch (error) {
        console.error(error);
        return {}
    }
    return response;
}