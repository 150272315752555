import Navbar from "../components/routing/Navbar";

const BackgroundLayout = ( { children, orientation="none" } ) => {

    let additions;
    if (orientation === "middle") {
        additions = "flex items-center justify-center";
    } else if (orientation === "none") {
        additions = "";
    }

    return (
        <div
            className={`bg-cover bg-fixed `}
            style={{
                backgroundImage: `url(${process.env.PUBLIC_URL}/images/background.jpeg)`,
                backgroundSize: "cover",
                backgroundPosition: "center",
                position: "fixed",
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                overscrollBehavior: "none"
            }}
        >
            <Navbar />
            <div className={`h-screen overflow-y-auto ${additions}`}>{children}</div>
        </div>
    );
};

export default BackgroundLayout;