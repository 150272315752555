import BackgroundLayout from "../layouts/BackgroundLayout";

const NotFound = () => {
    return (
        <BackgroundLayout orientation="middle">
            <div className="max-w-md py-6 px-8 bg-gray-100 shadow-lg rounded-lg" style={{marginBottom: "calc(30vh)"}}>
                <h1 className="text-3xl font-bold text-gray-900 mb-4">404 - Not Found</h1>
                <p className="text-gray-600 mb-8">The page you're looking for does not exist.</p>
                <a href="/" className="bg-gray-900 text-white py-2 px-4 rounded-lg hover:bg-gray-800">Go to Home</a>
            </div>
        </BackgroundLayout>
    );
};

export default NotFound;