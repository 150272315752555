import React from "react";
import { Link } from "react-router-dom";

const ButtonMailto = ({ mailto, label }) => {
    return (
        <Link className="inline-block bg-blue-500 hover:bg-blue-700 text-white font-bold py-0.5 px-2 rounded"
            to='#'
            onClick={(e) => {
                window.location.href = `mailto:${mailto}`;
                e.preventDefault();
            }}
        >
            {label}
        </Link>
    );
};

export default ButtonMailto;