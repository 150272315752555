import React from 'react';
import ButtonMailTo from "../utils/ButtonMailTo";

function generateJiraTaskList(team_list, accountId) {

    if (team_list === undefined || team_list.length == 0) {
        return "";
    }
    const team1 = team_list[0];
    const teamString = localStorage.getItem("teams");
    let pkey;
    JSON.parse(teamString).forEach(element => {
        if (element["name"] === team1) {
            pkey = element["key"];
        }
    })
    let res = `${process.env.REACT_APP_JIRA_SERVER}/jira/software/c/projects/${pkey}/issues/${pkey}?jql=`;
    res += encodeURIComponent(`assignee = currentUser()`);
    return res;
}

const UserCard = ({ description }) => {
    const { name, email, accountId, teams } = description;

    return (
        <div className="max-w-md mx-auto bg-white rounded-xl overflow-hidden md:max-w-2xl my-4">
            <div className="p-8">
                <div className="uppercase tracking-wide text-sm text-indigo-500 font-semibold">{name}</div>
                <div className="mt-2 text-gray-500">
                    <a href={generateJiraTaskList(teams, accountId)} target="_blank" rel="noopener noreferrer" className="inline-block bg-blue-500 hover:bg-blue-700 text-white font-bold py-0.5 px-2 rounded">
                        View all of {name}'s tasks
                    </a>
                </div>
                <div className="mt-2 text-gray-500">
                    {email ? (
                        <ButtonMailTo mailto={email} label={`Click here to email ${name}`} />
                    ) : (
                        <p style={{ color: 'red' }}>Waiting for the user to confirm their email</p>
                    )}
                </div>
                {/*<div className="mt-2 text-gray-500"><ButtonMailTo mailto={email} label={`Click here to email ${name}`} /></div>*/}
                <div className="mt-2 text-gray-500">
                    Teams:
                    <ul className="list-disc pl-5 mt-2">
                        {teams.map((team, index) => (
                            <li key={index}>{team}</li>
                        ))}
                    </ul>
                </div>
            </div>
        </div>
    );
};


export default UserCard;
