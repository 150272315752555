import React from 'react';

const TaskCard = ({ title, tasks }) => {


    return (
        <div className="max-w-md mx-auto bg-white rounded-xl overflow-hidden md:max-w-2xl my-4">
            <div className="p-8">
                <div className="uppercase tracking-wide text-sm text-indigo-500 font-semibold">{title.charAt(0).toUpperCase() + title.slice(1) + " tasks"}</div>
                <div className="mt-2 text-gray-500">
                    Tasks:
                    <ul className="list-disc pl-5 mt-2">
                        {tasks.map((item, index) => (
                            <li
                                key={index}
                                className="inline-block bg-blue-500 hover:bg-blue-700 text-white font-bold py-0.5 px-2 rounded mb-2"
                            >
                                <a href={item.link}>{`${item.team}: ${item.title}`}</a>
                            </li>
                        ))}
                    </ul>
                </div>
            </div>
        </div>
    );
};

const TaskGrid = ({ output }) => {
    return (
        <div className="Grid mt-8 ml-8 mr-8 grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-8 mx-auto my-8 px-4">
            {Object.keys(output).map((name, index) => (
                <div key={name} className="bg-white rounded-lg shadow-md overflow-hidden">
                    <TaskCard title={name} tasks={output[name]} />
                </div>
            ))}
        </div>
    );
};



export default TaskGrid;
