import React, {useEffect, useRef, useState} from 'react';
import Cookies from 'js-cookie';
import BackgroundLayout from "../layouts/BackgroundLayout";
import "firebase/compat/auth";
import firebase from "firebase/compat/app";
import { useAuthState } from "react-firebase-hooks/auth";
import {Navigate, useNavigate, useNavigation} from 'react-router-dom';
import classNames from 'classnames';


function Login() {
    const firebaseAuth = firebase.auth();
    useAuthState(firebaseAuth);
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const isAuth = useRef(false);
    const [isLoading, setIsLoading] = useState(false);
    const token = useRef("");
    const navigate = useNavigate();

    function handleEmailChange(event) {
        setEmail(event.target.value);
    }

    function handlePasswordChange(event) {
        setPassword(event.target.value);
    }

    const handleSubmit = (event) => {
        event.preventDefault();
        setIsLoading(true); // Set loading state to true

        try {
            firebaseAuth.signInWithEmailAndPassword(email, password).then(() => {
                 firebaseAuth.currentUser.getIdToken(true).then(t => {
                    token.current = t;
                    if (token.current !== "") {
                        isAuth.current = true;
                        console.log(isAuth.current);
                        Cookies.set("SessionJWT", token.current);
                        console.log(token.current);
                        navigate("/");
                        window.location.reload();
                    } else {
                        Cookies.remove("SessionJWT");
                    }
                    setIsLoading(false);
                });
            });
        } catch (error) {
            console.log(error);
            setIsLoading(false);
        }
    };


        return (
            <>
                {isAuth.current && <Navigate replace to="/"/>}
                <BackgroundLayout orientation="middle">
                    <div className="inset-0 bg-white opacity-95 p-20 rounded">
                        <form className="max-w-md mx-auto" onSubmit={handleSubmit}>
                            <div className="mb-4">
                                <label htmlFor="email" className="block mb-2 font-bold">Email</label>
                                <input
                                    type="email"
                                    id="email"
                                    className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:border-transparent sm:text-sm"
                                    value={email}
                                    onChange={handleEmailChange}
                                    required
                                    placeholder="Email"
                                />
                            </div>
                            <div className="mb-6">
                                <label htmlFor="password" className="block mb-2 font-bold">Password</label>
                                <input
                                    type="password"
                                    id="password"
                                    className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:border-transparent sm:text-sm"
                                    value={password}
                                    onChange={handlePasswordChange}
                                    required
                                    placeholder="Password"
                                />
                            </div>
                            <button
                                type="submit"
                                className={classNames(
                                    'w-full px-4 py-2 text-white bg-indigo-500 rounded-md hover:bg-indigo-400 focus:outline-none focus:bg-indigo-400',
                                    {
                                        'opacity-50 pointer-events-none': isLoading,
                                    }
                                )}
                                disabled={isLoading}
                            >
                                {isLoading ? (
                                    <svg
                                        className="animate-spin h-5 w-5 mr-3 text-white"
                                        xmlns="http://www.w3.org/2000/svg"
                                        fill="none"
                                        viewBox="0 0 24 24"
                                    >
                                        <circle
                                            className="opacity-25"
                                            cx="12"
                                            cy="12"
                                            r="10"
                                            stroke="currentColor"
                                            strokeWidth="4"
                                        ></circle>
                                        <path
                                            className="opacity-75"
                                            fill="currentColor"
                                            d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm12 0a8 8 0 100-16v3a5 5 0 010 10v3l-4-3v-2h-2v2l-4 3v-3a8 8 0 008 8 8 8 0 008-8z"
                                        ></path>
                                    </svg>
                                ) : (
                                    'Login'
                                )}
                            </button>
                        </form>
                    </div>
                </BackgroundLayout>
            </>
        );

}

export default Login;
