import {useEffect, useState} from "react";
import {getTeamsAndUsers} from "../services/apis";
import BackgroundLayout from "../layouts/BackgroundLayout";
import LoadingSpinner from "../views/LoadingSpinner";
import {Navigate} from "react-router-dom";
import AdminGrid from "../views/AdminGrid";
import Cookies from 'js-cookie';
import {BadAuthenticationError} from "../services/errors";

const Admin = ( { type="users" } ) => {

    const [auth, setAuth] = useState(true);
    const [users, setUsers] = useState([]);
    const [teams, setTeams] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        if (Cookies.get("SessionJWT") === undefined) {
            setAuth(false);
        }
        else if (!loading) { }
        else {
            getTeamsAndUsers()
                .then(res => {
                    const {u, t} = res;
                    setUsers(u);
                    setTeams(t);
                    setLoading(false);
                    localStorage.setItem('users', JSON.stringify(u));
                    localStorage.setItem('teams', JSON.stringify(t));
                })
                .catch(error => {
                    if (error instanceof BadAuthenticationError) {
                        console.log("Caught an error.");
                        setAuth(false);
                    }
                    setLoading(false);
                })
        }
    })

    function getCorrectItem() {
        if (type === "users") {
            return users;
        } else if (type === "teams") {
            return teams;
        }
    }

    if (!auth) {
        return (<Navigate to="/login" replace/>);
    }

    if (loading) {
        return (
            <BackgroundLayout orientation="middle">
                <LoadingSpinner />
            </BackgroundLayout>
        );

    } else {

        return (
            <BackgroundLayout >
                <AdminGrid type={type} map={getCorrectItem()}/>
            </BackgroundLayout>
        )

    }

};

export default Admin;