import {Navigate, useLocation} from "react-router-dom";

export const ProtectedRoute = ({ isAuthenticated, children} ) => {

    console.log(isAuthenticated);
    const location = useLocation()
    if (!isAuthenticated) {
        return <Navigate to="/login" state={{from: location}} replace/>
    }
    return children;
};

export const LoginRoute = ({isAuthenticated, children}) => {

    const location = useLocation();

    if (!isAuthenticated) {
        return children;
    }
    return <Navigate to="/" state={{from: location}} replace/>
};