
export class BadAuthenticationError extends Error {
    constructor(message) {
        super(message);
        this.name = 'BadAuthenticationError';
    }
};

export class VisiblePopupError extends Error {
    constructor(message) {
        super(message);
        this.name = 'VisiblePopupError';
    }
};