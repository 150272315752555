import BackgroundLayout from "../layouts/BackgroundLayout";
import {useEffect, useState} from "react";
import {getTasks, getTeamsAndUsers} from "../services/apis";
import {Navigate} from "react-router-dom";
import LoadingSpinner from "../views/LoadingSpinner";
import TaskGrid from "../views/TaskGrid";
import Cookies from 'js-cookie';

const Home = ( ) => {

    const [auth, setAuth] = useState(true);
    const [tasks, setTasks] = useState([])
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        if (Cookies.get("SessionJWT") === undefined) {
            setAuth(false);
        }
        else if (!loading) { }
        else {
            getTasks()
                .then(res => {
                    setTasks(res);
                    console.log(tasks);
                    setLoading(false);
                })
                .catch(error => {
                    console.log("Caught an error.");
                    setAuth(false);
                    setLoading(false);
                })
        }
    })

    if (!auth) {
        return (<Navigate to="/login"/>);
    }

    if (loading) {
        return (
            <BackgroundLayout orientation="middle">
                <LoadingSpinner />
            </BackgroundLayout>
        );

    } else {

        return (
            <BackgroundLayout >
                <TaskGrid output={tasks}/>
            </BackgroundLayout>
        )

    }


};

export default Home;