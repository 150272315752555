import {useCallback, useState} from "react";
import UserCard from "../components/cards/UserCard";
import TeamCard from "../components/cards/TeamCard";
import Modal from "react-modal";
import PopupForm from "../components/forms/PopupForm";
import {useModal} from "react-hooks-use-modal";

const AdminGrid = ( { map, type="users" } ) => {

    const [Modal, open, close, isOpen] = useModal('root', {
        preventScroll: true,
        closeOnOverlayClick: false
    });

    function getCardType(item, type) {
        if (type === "users") {
            return (<UserCard description={item}/>);
        } else {
            return (<TeamCard description={item}/>);

        }
    }

    const Grid = () => {
        return (
            <div className="Grid mt-8 ml-8 mr-8 grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-8 mx-auto my-8 px-4">
                {map.map((item, i) => (
                    <div key={i} className="bg-white rounded-lg shadow-md overflow-hidden">
                        {getCardType(item, type)}
                    </div>
                ))}
            </div>
        );
    };

    return (
        <div className="relative max-h-screen">
            <Grid />
            <button
                className="fixed bottom-6 right-6 z-10 inline-flex items-center justify-center w-16 h-16 text-gray-100 transition-colors duration-150 bg-green-500 rounded-full focus:shadow-outline hover:bg-green-600"
                onClick={open}
            >
                <svg
                    className="w-8 h-8"
                    fill="none"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                >
                    <path d="M12 6v6m0 0v6m0-6h6m-6 0H6" />
                </svg>
            </button>
            <Modal isOpen={isOpen} onRequestClose={close}>
                    <PopupForm type={type} close={close}/>
            </Modal>
        </div>
    );

};

export default AdminGrid;