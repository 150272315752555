import './App.css';
import React, {useState} from "react";
import {BrowserRouter, Route, Routes} from "react-router-dom";
import { ProtectedRoute, LoginRoute } from "./components/routing/RouteTypes";
import Home from "./pages/Home";
import Admin from "./pages/Admin";
import NotFound from "./pages/NotFound";
import Login from "./pages/Login";
import "firebase/compat/auth";
import Cookies from 'js-cookie';


function formatProtectedRoutes(Component, path, authenticated) {

    return (
        <Route path={path} element={
            <ProtectedRoute isAuthenticated={authenticated}>
                {Component}
            </ProtectedRoute>}/>
    );
}

function formatLoginRoute(Component, path, authenticated) {
    return (
        <Route path={path} element={
            <LoginRoute isAuthenticated={authenticated}>
                {Component}
            </LoginRoute>}/>
    );
}

const App = () => {

    let auth;
    if (Cookies.get("SessionJWT") !== undefined) {
        auth = true;
    } else {
        auth = false;
    }

    console.log(auth);

    return (
        <BrowserRouter>
            <Routes>
                {/*{formatLoginRoute(<Login />, "/login", auth)}*/}
                <Route element={ <Login />} path="/login"/>
                {formatProtectedRoutes(<Home />, "/", auth)}
                {formatProtectedRoutes(<Admin type="users"/>,"/users", auth)}
                {formatProtectedRoutes(<Admin type="teams"/>, "teams", auth)}
                <Route element={<NotFound/>} path="*"/>
            </Routes>
        </BrowserRouter>
    );
};

export default App;