import React from 'react';

const LoadingSpinner = () => {
    return (
        <div className="flex items-center justify-center bg-white rounded-lg p-8">
            <svg className="animate-spin h-16 w-16 text-gray-500" viewBox="0 0 24 24">
                <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4" />
                <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647zM12 20.5A8.5 8.5 0 0017.938 19l-2.648-3A5.5 5.5 0 1112 6.5V20.5zm3.063-3.938A8.002 8.002 0 0112 20.5V24c3.042 0 5.824-1.135 7.938-3l-2.647-3z" />
            </svg>
            <p className="text-gray-500 font-medium text-lg ml-4">Loading...</p>
        </div>
    );
};

export default LoadingSpinner;